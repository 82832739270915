<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                   <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                     <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
           <v-col>
                <v-card color="blue lighten-5">
                    <v-container fluid>
                        <v-row>
                              <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="company" 
                                        :items="companys" 
                                        item-value="company_id" 
                                        item-text="company_name" 
                                        label="Company ID" 
                                        clearable 
                                        prepend-icon="mdi-home-variant"
                                        @change="(event) => [UpdateOffice(event), updateIdemploye(event)]"
                                        :disabled="disabled_company"
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="office" 
                                        :items="offices" 
                                        item-value="office_id" 
                                        item-text="office_name" 
                                        label="Office ID" 
                                        clearable 
                                        prepend-icon="mdi-store"
                                        @change="(event) => [updatedepartement(event), updateIdemploye(event)]"
                                        :disabled="disabled_office"
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete 
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="depart" 
                                        :items="departs" 
                                        default="" 
                                        item-value="dept_id" 
                                        item-text="dept_name" 
                                        label="Department" 
                                        clearable
                                        prepend-icon="mdi-home-assistant"
                                        @change="(event) => [updatedivision(event), updateIdemploye(event)]"
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete 
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="division" 
                                        :items="divis" 
                                        default="" 
                                        item-value="division_id" 
                                        item-text="division_name" 
                                        label="Division" 
                                        clearable 
                                        prepend-icon="mdi-flag-triangle"
                                        @change="(event) => [updateIdemploye(event), updateIdemploye(event)]"
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete 
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="employee" 
                                        :items="employees" 
                                        default="" 
                                        item-value="idemployee" 
                                        item-text="idemployee" 
                                        label="Employee ID" 
                                        clearable 
                                        prepend-icon="mdi-library"
                                        @change="(event) => [updateName(event), updateIdemploye(event)]" 
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete 
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="namex" 
                                        :items="names" 
                                        default="" 
                                        item-value="name" 
                                        item-text="name" 
                                        label="Name" 
                                        clearable 
                                        prepend-icon="mdi-emoticon" 
                                        >
                                    </v-autocomplete>
                                </v-col>
                                 <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date From"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date to"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                             <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-3 ml-2" 
                                    color="info" elevation="2" large @click="GetData()">Search</v-btn>
                            </v-col>   
                        </v-row>
                    </v-container>
                </v-card>
                     <v-row class="mt-2" style="padding-bottom:50px">
                <v-col cols="12">
                    <v-card class="rounded-l elevation-5 ml-3" color="blue lighten-5">
                    <v-card-text>
                         <h5>Result</h5>
                        <v-data-table
                            dense
                            :headers="headers"
                            :loading="loading"
                            :items="menu_headers"
                            loading-text="Please wait, retrieving data"
                            class="elevation-1"
                            >  
                         </v-data-table>
                       </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            </v-col> 
        </v-row>
    </v-container>
</template>

<script>
import {backendApi} from "../../../backend-api/backend-api-sr"
export default {
    data() {
        return {
                loading: false,
                snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search:'',
            breadcumbs: [
                {
                    text: 'Viva',
                    disabled: false,
                    href: '/admin/viva',
                },
                {
                    text: 'HRIS',
                    disabled: false,
                    href: '#',
                },
                {
                    text: 'Attendance Report',
                    disabled: true,
                }
            ],
            menu_headers:[],
              headers: [
                { text: 'ID', value: 'idemployee', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'NAME.', value: 'name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DEPT.', value: 'dept_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'DIVISION', value: 'division_name', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'FULL TIME', value: 'FULL TIME', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SUNDAY.', value: 'SUNDAY', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ALPHA', value: 'ALPHA', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'C1', value: 'C1', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'C2', value: 'C2', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'C3', value: 'C3', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'C4', value: 'C4', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'C5', value: 'C5', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUTI', value: 'CUTI', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUTI 1/2 HARI', value: 'CUTI 1/2 HARI', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'IJIN 1/2 HARI', value: 'IJIN 1/2 HARI', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'IJIN TIDAK MASUK', value: 'IJIN TIDAK MASUK', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'IJIN SAKIT', value: 'IJIN SAKIT', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'HOLIDAY', value: 'HOLIDAY', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'SAKIT SURAT DOKTER', value: 'SAKIT SURAT DOKTER', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TUGAS LUAR', value: 'TUGAS LUAR', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'BELUM GABUNG', value: 'BELUM GABUNG', align: 'left', class: 'primary--text blue lighten-5' }
            ],
            date_from: '',
            date_to: '',
            modal_to: false,
            modal: false,
            offices:[],
            departs:[],
            companys:[],
            divis:[],
            employees:[],
            names:[],
            depart:'',
            namex:'',
            employee:'',
            company:'',
            office:'',
            division:'',
            disabled_company: false,
            disabled_office: false
        }

    },
    mounted () {
    this.getCompany()
    this.getEnvConf()

    },
    methods: {
    async getEnvConf(){
            const respData = await backendApi.fetchCore(`/api/get_group_user?entity_id=VIVA&appl_id=WEBVIVA`, null, false, false, false)
            if (respData.status === 200) {
                console.log(respData.data)
                this.group_id = respData.data.data
                
               
            const respCompany_id = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=VIVA&appl_id=WEBVIVA&var_id=COMPANY_ID&groupid=${this.group_id}`, null, false, false, false) 

            //console.log(respCompany_id)
            if (respCompany_id.data.data != null) {
                this.company = respCompany_id.data.data
                this.disabled_company = true
                this.UpdateOffice()
                this.updateIdemploye()
                this.updatedepartement()
                this.updatedivision()
                this.updateName()
                
            } else {
                this.company = ''
                this.disabled_company = false
            }
            const respOffice_id = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=VIVA&appl_id=WEBVIVA&var_id=OFFICE_ID&groupid=${this.group_id}`, null, false, false, false) 

            console.log(respOffice_id)
            if (respOffice_id.data.data != null) {
                this.office = respOffice_id.data.data
                this.disabled_office = true
                this.UpdateOffice()
                this.updateIdemploye()
                this.updatedepartement()
                this.updatedivision()
                this.updateName()
            } else {
                this.office = ''
                this.disabled_office = false
            }
                    
            }
                
        },    
    
    async getCompany(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/attendance/GetCompany`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.companys = res.data
            })
        },
    async UpdateOffice(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/attendance/GetOffice?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&office_id=${this.office ? this.office : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.offices = res.data
            })
        },
    async updatedepartement(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/attendance/Dept?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&office_id=${this.office ? this.office : ""}&dept_id=${this.depart ? this.depart : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
             this.departs = res.data.datas
             
            })
        },

    async updatedivision(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/attendance/Divis?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&idemployee=${this.employee ? this.employee : ""}&dept_id=${this.depart ? this.depart : ""}&division_id=${this.division ? this.division : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.divis = res.data.datas
            })
        },    
    async updateIdemploye(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/attendance/Employe?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&idemployee=${this.employee ? this.employee : ""}&dept_id=${this.depart ? this.depart : ""}&division_id=${this.division ? this.division : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.employees = res.data.datas
            })
        }, 
    async updateName(){
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/attendance/Employe?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&idemployee=${this.employee ? this.employee : ""}&dept_id=${this.depart ? this.depart : ""}&division_id=${this.division ? this.division : ""}&name=${this.namex ? this.namex : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.names = res.data.datas
            })
        },
        
    async GetData(){
            var startDate = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10).replace(/-/g,"") : '';
            var endDate = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10).replace(/-/g,"") : new Date().toISOString().substr(0, 10).replace(/-/g,"");
            this.$store.dispatch('setOverlay', true)
            await axios.get(`${process.env.VUE_APP_URL}/api/viva/attendance/GetData?search=${this.$store.state.text}&company_id=${this.company ? this.company : ""}&office_id=${this.office ? this.office : ""}&dept_id=${this.depart ? this.depart : ""}&division_id=${this.division ? this.division : ""}&idemployee=${this.employee ? this.employee : ""}&name=${this.namex ? this.namex : ""}&dateFrom=${startDate ? startDate : ""}&dateTo=${endDate ? endDate : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.menu_headers = res.data.datas
                this.$store.dispatch('setOverlay', false)
            })
        }, 


        },
    



}
</script>

<style>

</style>